@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
}

.d-none{
  display: none!important;
}
:root {
  /* Primary */
  --p: #000000;
  /* Black Background */
  /* Secondary */
  --s: #ff6600;
  /* Pink Text for Buttons/Special Cases */
  /* Secondary Light */
  --s_l: #ffb3d1;
  /* Light Pink */
  /* Primary Bright */
  --p_b: #42b4e7;
  /* Optional, can keep or change */
  /* Primary Light */
  --p_l: #ecf8fd;
  /* Optional, can keep or change */
  /* Primary Light Dark */
  --p_l_d: #9ddaf3;
  /* Optional, can keep or change */
  /* White Dark */
  --w_d: #ffffff;
  /* White Text */
  /* Dark */
  --d: #151414;
  /* Dark */
  /* Dark Light */
  --d_l: #202020;
  /* Dark Light */
  /* ClickCart Color */
  --c_c: #060622;
  /* Optional, can keep or change */
  /* Light Page Background */
  --l_p_bg: #000000;
  /* Black */
  --disable: #a9a9a9;
  /* Optional, can keep or change */
  --page_padding: 3rem;
  --gradient: linear-gradient(142deg, #000000 10%, #ff69b4 70%);
  /* Black to Pink */
  /* Small Box Shadow */
  --sbs: 0 1px 6px rgba(255, 255, 255, 0.1);
  /* Light Shadow */
  /* Dashboard Border Radius */
  --d_b_r: 10px;
}

/* Additional Styles */
body {
  background-color: var(--p);
  color: var(--w_d);
}

button,
.special-case {
  color: var(--s);
  background-color: var(--p);
  /* Black background for buttons */
}


@media screen and (max-width: 768px) {
  :root {
    --page_padding: 1.5rem;
  }
}